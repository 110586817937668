:root {
  --color-main: #06f;
  --color-primary: #ca6b18;
  --color-primaryHover: #dc8e4a;
  --color-backgroundAllergo: #fcf9f2;
  --color-neutral: #e8e5de;
  --color-secondary: #1b8f42;
  --color-secondaryHover: #23a64f;
}

.bg-allergo {
  background-color: var(--color-backgroundAllergo) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

*, * button:focus {
  outline: 0;
}

html::-webkit-scrollbar {
  background: #f1f1f1;
  border-radius: 10px;
  width: 10px;
  height: 20px;
}

html::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

html::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px;
}

body {
  font-family: Inter, sans-serif;
  font-size: .9em;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  position: relative;
  overflow-x: hidden;
}

.container, .container-fluid {
  z-index: 5;
  position: relative;
}

.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) {
  margin-left: -15px;
  margin-right: -15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) > * {
  padding-left: 15px;
  padding-right: 15px;
}

h1, h2, h3, h4 {
  margin: 0;
  font-weight: 700;
  line-height: 1.4;
}

h5, h6 {
  margin: 0;
  font-weight: 500;
  line-height: 1.4;
}

body a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

body a:focus {
  outline: 0;
}

body ul {
  margin: 0;
  list-style-type: none;
}

.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  -webkit-appearance: none;
}

.btn:active, .btn:active:focus, .btn:active:hover, .btn:focus, .btn:hover, .btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  outline: 0;
  transition: all .6s;
  box-shadow: 0 0 #0000;
}

a {
  cursor: pointer;
}

a, span, img {
  color: inherit;
  transition: all .3s;
  display: inline-block;
  text-decoration: none !important;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

p {
  margin: 0;
  font-size: 14px;
  line-height: 1.7;
}

.bi {
  display: inline-block;
}

.object-fit-contain {
  object-fit: contain;
}

.mt-n1 {
  margin-top: -.25rem !important;
}

.animation-rotate-center {
  animation: 100s linear infinite both rotate-center;
}

@keyframes rotate-center {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animation-slide-up-down {
  animation: 1.7s ease-in-out infinite alternate both slide-up-down;
}

.animation-slide-up-down-delayed {
  animation: 3s ease-in-out infinite alternate both slide-up-down;
}

@keyframes slide-up-down {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.animation-wave {
  animation: 2s ease-in-out infinite alternate both wave;
}

.animation-wave-delayed {
  animation: 3s ease-in-out infinite alternate both wave;
}

@keyframes wave {
  0% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(-1deg);
  }
}

.animation-scale-up-down {
  animation: 1s ease-in-out infinite alternate both scale-up-down;
}

@keyframes scale-up-down {
  0% {
    transform: scale(.9);
  }

  100% {
    transform: scale(1);
  }
}

.animation-scale-left-right {
  animation: 1s ease-in-out infinite alternate both scale-left-right;
}

@keyframes scale-left-right {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

.animation-fade-pulse {
  animation: 5s ease-in-out infinite alternate both fade-pulse;
}

@keyframes fade-pulse {
  0%, 100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

#preloader {
  z-index: 9999999;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}

#preloader:after, #preloader:before {
  content: "";
  background: linear-gradient(to right, #7491f8, #80d3ff);
  width: 100%;
  height: 50%;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  position: fixed;
  left: 0;
}

#preloader:before {
  top: 0;
}

#preloader:after {
  bottom: 0;
}

#preloader.isdone {
  visibility: hidden;
  transition-delay: 1.5s;
}

#preloader.isdone:after, #preloader.isdone:before {
  height: 0;
  transition: all .7s cubic-bezier(1, 0, .55, 1);
}

#content-wrapper {
  color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 20px;
  left: 0;
}

#to-top {
  background-color: var(--color-secondary);
  color: #fff;
  opacity: 0;
  text-align: center;
  z-index: 9999;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 7px;
  transition: all .4s;
  position: fixed;
  bottom: 30px;
  right: -50px;
  box-shadow: 0 5px 10px #0002;
}

#to-top.show {
  opacity: 1;
  right: 30px;
}

.icon-30 {
  width: 30px;
  height: 30px;
}

.icon-140 {
  width: 140px;
  height: 140px;
}

.btn {
  cursor: pointer;
  text-align: center;
  padding: .75rem 2rem;
  overflow: hidden;
}

.btn:hover {
  background: var(--color-main);
  border-color: #0000;
}

.btn.btn-primary {
  background: var(--color-primary);
  border-color: var(--color-primary);
}

.btn.btn-primary:hover {
  background: var(--color-primaryHover);
  border-color: #0000;
}

.btn.btn-secondary {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}

.btn.btn-secondary:hover {
  background: var(--color-secondaryHover) border-color: transparent;
}

.btn.btn-neutral {
  background: #dedbd4;
  border-color: #dedbd4;
}

.btn.btn-neutral:hover {
  background: #e8e5de;
  border-color: #0000;
}

.btn.btn-sm {
  padding: .5rem 1rem;
  font-size: .75rem;
}

.btn.btn-outline {
  background: none;
}

.btn-outline.btn-secondary {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
}

.btn-outline.btn-secondary:hover {
  background-color: var(--color-secondary);
  color: #fff;
}

.section {
  padding: 100px 0;
}

.section-head h6 {
  letter-spacing: 2px;
  margin-bottom: 20px;
  font-size: 13px;
}

.section-head h2 {
  color: #000;
  font-size: 45px;
}

.section-head h2 span {
  padding: 10px 20px;
  position: relative;
}

.section-head h2 span small {
  z-index: 2;
  color: #fff;
  font-size: 45px;
  position: relative;
}

.section-head h2 span:before {
  content: "";
  z-index: 1;
  background-image: url("marker-highlight.146ac3dc.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.section-head p {
  font-size: 1.125rem;
}

.nav-container {
  z-index: 999;
}

.navbar.nav-scroll {
  border-bottom: 1px solid #ca6b181a;
  border-radius: 0;
  width: 100%;
  margin: 0;
  transition: transform .5s;
  position: fixed;
  top: -100px;
  left: 0;
  transform: translateY(100px);
  box-shadow: 0 10px 30px #0000001a;
}

.navbar .navbar-nav {
  pointer-events: none;
  visibility: hidden;
}

.navbar.nav-scroll .navbar-nav {
  pointer-events: auto;
  visibility: visible;
}

.navbar {
  z-index: 99;
  border-radius: 30px;
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
}

.navbar.nav-scroll {
  backdrop-filter: blur(10px);
  background: #ca6b1833;
}

.navbar-toggler {
  background-color: var(--color-secondary);
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 .25rem var(--color-secondary);
  background-color: var(--color-secondaryHover);
}

.navbar .container-fluid {
  padding: 0 3vw;
}

.navbar .nav-item .nav-link {
  color: #000;
  transition: color .3s, font-weight .3s;
}

.navbar .nav-item .nav-link.active {
  font-weight: bold;
}

.navbar .nav-item .nav-link:hover, .navbar .nav-item .nav-link.active {
  color: var(--color-primary);
}

.hero .content {
  background-image: linear-gradient(to top left, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  height: calc(100vh - 1rem);
}

.hero .info h6 {
  letter-spacing: 5px;
}

.hero .info h1 {
  font-size: 5.25rem;
  line-height: 1.1;
}

.hero .illustration-tray {
  object-fit: contain;
  object-position: left;
  z-index: 10;
  width: 20%;
  position: absolute;
  top: 20%;
  left: 0;
}

.hero .illustration-plate {
  z-index: 10;
  width: 30%;
  position: absolute;
  bottom: 5%;
  right: -30px;
}

@media screen and (width >= 576px) {
  .hero .content {
    border-radius: 1.5rem;
    height: calc(100vh - 3rem);
  }
}

.solution-avatar {
  background-image: linear-gradient(to top left, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  transition: box-shadow .3s;
}

.solution-avatar:hover {
  box-shadow: 0 46px 77px #0a2b5326;
}

.solution-avatar img {
  width: 240px;
  height: 240px;
  margin-top: -20px;
  margin-bottom: -20px;
}

.solution-avatar--overflow-bottom img {
  margin-top: 0;
  margin-bottom: -30px;
}

.solution-avatar--overflow-extra img {
  width: 260px;
  height: 260px;
  margin-top: -40px;
}

#pricing[data-js-period="monthly"] .price-monthly {
  display: inline-block;
}

#pricing[data-js-period="monthly"] .price-yearly {
  display: none;
}

#pricing[data-js-period="monthly"] .price-period-switch--monthly {
  pointer-events: none;
}

#pricing[data-js-period="yearly"] .price-monthly {
  display: none;
}

#pricing[data-js-period="yearly"] .price-yearly {
  display: inline-block;
}

#pricing[data-js-period="yearly"] .price-period-switch--yearly {
  pointer-events: none;
}

.price-period-switch .form-switch {
  gap: 10px;
}

.price-period-switch .form-check-input {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  cursor: pointer;
  float: none;
  margin: 0;
  padding: 10px 20px;
}

.price-period-switch .form-check-input:focus {
  box-shadow: 0 0 0 .25rem #dc8e4a66;
}

.price-period-switch .form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.price-period-switch .form-check-label {
  cursor: pointer;
}

.price-period-switch-discount {
  color: #646464;
  background: none;
  border: 1px solid #646464;
}

#pricing[data-js-period="yearly"] .price-period-switch-discount {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}

.price-card {
  background-color: var(--color-neutral);
  border-radius: 1.25rem;
}

.price-card--highlighted {
  background-image: linear-gradient(to top left, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
}

#demo {
  padding-bottom: 200px;
}

.demo-qr-code img {
  border: 5px solid var(--color-neutral);
  border-radius: .5rem;
  width: 40%;
  max-width: 200px;
  padding: 10px;
}

@media screen and (width >= 768px) {
  .demo-qr-code figcaption {
    font-size: .9rem !important;
  }
}

@media screen and (width >= 992px) {
  .demo-qr-code figcaption {
    font-size: initial !important;
  }
}

.imprint .navbar .navbar-nav {
  pointer-events: auto;
  visibility: visible;
}

.imprint .content {
  background-image: linear-gradient(to top left, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  min-height: calc(100vh - 1rem);
  padding-top: 80px;
}

@media screen and (width >= 576px) {
  .imprint .content {
    border-radius: 1.5rem;
    min-height: calc(100vh - 3rem);
    padding-top: 100px;
  }
}

@media screen and (width >= 768px) {
  .imprint .content {
    padding-top: 140px;
  }
}

@media screen and (width <= 991px) {
  .section {
    padding: 70px 0;
  }

  .section-head h2, .section-head h2 span small {
    font-size: 30px;
  }

  .navbar:has(.navbar-collapse.show), .navbar:has(.navbar-collapse.collapsing) {
    backdrop-filter: blur(10px);
    background: #ca6b1833 !important;
  }

  .navbar .navbar-nav {
    pointer-events: auto;
    visibility: visible;
  }

  .navbar .container-fluid {
    padding: 0 20px;
  }

  .navbar .container-fluid .navbar-collapse {
    margin-top: 20px;
  }

  .hero .illustration-tray {
    display: none;
  }

  .hero .illustration-plate {
    z-index: 1;
    width: 45%;
    bottom: 0;
  }
}

@media screen and (width <= 575px) {
  .hero .info h6 {
    font-size: 1rem !important;
  }

  .hero .info h1 {
    font-size: 3rem;
  }
}
/*# sourceMappingURL=index.fe1f7518.css.map */
