/* ===============================================================
   [ * variable Section ]
================================================================*/

:root {
  --color-main: rgba(0, 102, 255, 1);
  --color-primary: rgba(202, 107, 24, 1);
  --color-primaryHover: rgba(220, 142, 74, 1);
  --color-backgroundAllergo: rgba(252, 249, 242, 1);
  --color-neutral: rgba(232, 229, 222, 1);
  --color-secondary: rgba(27, 143, 66, 1);
  --color-secondaryHover: rgba(35, 166, 79, 1);
}

.bg-allergo {
  background-color: var(--color-backgroundAllergo) !important;
}

.color-secondary {
  color: var(--color-secondary) !important;
}

/* ===============================================================
   [ * global Section ]
================================================================*/

*,
* button:focus {
  outline: 0;
}

html::-webkit-scrollbar {
  height: 20px;
  width: 10px;
  background: #f1f1f1;
  border-radius: 10px;
}

html::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

html::-webkit-scrollbar-corner {
  background: #999;
  border-radius: 10px;
}

body {
  font-family: "Inter", sans-serif;
  text-decoration: none;
  font-size: 0.9em;
  overflow-x: hidden;
  position: relative;
  font-weight: 400;
  line-height: 1.5;
}

.container,
.container-fluid {
  position: relative;
  z-index: 5;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) {
  margin-right: -15px;
  margin-left: -15px;
}

.row:not(.gx-0):not(.gx-1):not(.gx-2):not(.gx-3):not(.gx-4):not(.gx-5) > * {
  padding-right: 15px;
  padding-left: 15px;
}

h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin: 0;
  line-height: 1.4;
}

h5,
h6 {
  font-weight: 500;
  margin: 0;
  line-height: 1.4;
}

body a:hover {
  color: var(--color-primary);
  text-decoration: none;
}

body a:focus {
  outline: 0;
}

body ul {
  list-style-type: none;
  margin: 0;
}

.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  -webkit-appearance: none;
}

.btn:active,
.btn:active:focus,
.btn:active:hover,
.btn:focus,
.btn:hover,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  box-shadow: 0 0 0 transparent;
  outline: 0;
  transition: all 0.6s ease;
}

a {
  cursor: pointer;
}

a,
span,
img {
  color: inherit;
  display: inline-block;
  text-decoration: none !important;
  transition: all 0.3s ease;
}

ul {
  padding: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
}

p {
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
}

.bi {
  display: inline-block;
}

/* ===============================================================
   Bootstrap 5.0.2 polyfills (supported in 5.3)
================================================================*/

.object-fit-contain {
  object-fit: contain;
}
.mt-n1 {
  margin-top: -0.25rem !important;
}

/* ===============================================================
   [ * animation Section ]
================================================================*/

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
*/
.animation-rotate-center {
  -webkit-animation: rotate-center 100s linear infinite both;
  animation: rotate-center 100s linear infinite both;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/**
 * ----------------------------------------
 * animation slide-up-down
 * ----------------------------------------
*/
.animation-slide-up-down {
  -webkit-animation: slide-up-down 1.7s ease-in-out infinite alternate both;
  animation: slide-up-down 1.7s ease-in-out infinite alternate both;
}

.animation-slide-up-down-delayed {
  -webkit-animation: slide-up-down 3s ease-in-out infinite alternate both;
  animation: slide-up-down 3s ease-in-out infinite alternate both;
}

@-webkit-keyframes slide-up-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
@keyframes slide-up-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}
/**
 * ----------------------------------------
 * animation wave
 * ----------------------------------------
*/
.animation-wave {
  -webkit-animation: wave 2s ease-in-out infinite alternate both;
  animation: wave 2s ease-in-out infinite alternate both;
}

.animation-wave-delayed {
  -webkit-animation: wave 3s ease-in-out infinite alternate both;
  animation: wave 3s ease-in-out infinite alternate both;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
}
@keyframes wave {
  0% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  100% {
    -webkit-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
}
/**
 * ----------------------------------------
 * animation scale-up-down
 * ----------------------------------------
*/
.animation-scale-up-down {
  -webkit-animation: scale-up-down 1s ease-in-out infinite alternate both;
  animation: scale-up-down 1s ease-in-out infinite alternate both;
}

@-webkit-keyframes scale-up-down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-down {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation scale-left-right
 * ----------------------------------------
*/

.animation-scale-left-right {
  -webkit-animation: scale-left-right 1s ease-in-out infinite alternate both;
          animation: scale-left-right 1s ease-in-out infinite alternate both;
}

@-webkit-keyframes scale-left-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes scale-left-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

/**
 * ----------------------------------------
 * animation fade-pulse
 * ----------------------------------------
*/
.animation-fade-pulse {
  -webkit-animation: fade-pulse 5s ease-in-out infinite alternate both;
  animation: fade-pulse 5s ease-in-out infinite alternate both;
}

@-webkit-keyframes fade-pulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes fade-pulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* ===============================================================
   [ * preloader Section ]
================================================================*/

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
  pointer-events: none;
}

#preloader:after,
#preloader:before {
  content: "";
  position: fixed;
  left: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(to right, #7491f8, #80d3ff);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

#preloader:before {
  top: 0;
}

#preloader:after {
  bottom: 0;
}

#preloader.isdone {
  visibility: hidden;
  transition-delay: 1.5s;
}

#preloader.isdone:after,
#preloader.isdone:before {
  height: 0;
  transition-delay: 1s;
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
}

#content-wrapper {
  color: #FFF;
  position: fixed;
  left: 0;
  top: 20px;
  width: 100%;
  height: 100%;
}

/* ===============================================================
   [ * scroll to top Section ]
================================================================*/

#to-top {
  background-color: var(--color-secondary);
  border-radius: 50%;
  bottom: 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.133);
  color: #fff;
  height: 40px;
  opacity: 0;
  padding: 7px;
  position: fixed;
  right: -50px;
  text-align: center;
  transition: all 0.4s ease;
  width: 40px;
  z-index: 9999;
}
  #to-top.show {
    right: 30px;
    opacity: 1;
  }

/* ===============================================================
   [ * icons Section ]
================================================================*/

.icon-30 {
  width: 30px;
  height: 30px;
}

.icon-140 {
  width: 140px;
  height: 140px;
}

/* ===============================================================
   [ * buttons Section ]
================================================================*/

.btn {
  cursor: pointer;
  overflow: hidden;
  padding: 0.75rem 2rem;
  text-align: center;
}
.btn:hover {
  background: var(--color-main);
  border-color: transparent;
}
.btn.btn-primary {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
  .btn.btn-primary:hover {
    background: var(--color-primaryHover);
    border-color: transparent;
  }
.btn.btn-secondary {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}
  .btn.btn-secondary:hover {
    background: var(--color-secondaryHover)
    border-color: transparent;
  }
.btn.btn-neutral {
  background: rgba(222, 219, 212, 1);
  border-color: rgba(222, 219, 212, 1);
}
  .btn.btn-neutral:hover {
    background: rgba(232, 229, 222, 1);
    border-color: transparent;
  }

.btn.btn-sm {
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

.btn.btn-outline {
  background: transparent;
}

.btn-outline.btn-secondary {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
}
  .btn-outline.btn-secondary:hover {
    background-color: var(--color-secondary);
    color: #fff;
  }

/* ===============================================================
   [ * Sections ]
================================================================*/

.section {
  padding: 100px 0;
}

.section-head h6 {
  font-size: 13px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.section-head h2 {
  color: #000;
  font-size: 45px;
}
.section-head h2 span {
  position: relative;
  padding: 10px 20px;
}
.section-head h2 span small {
  position: relative;
  z-index: 2;
  font-size: 45px;
  color: #fff;
}
.section-head h2 span::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-image: url(../img/marker-highlight.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

.section-head p {
  font-size: 1.125rem;
}

/* ===============================================================
   [ * navbar Section ]
================================================================*/

.nav-container {
  z-index: 999;
}

.navbar.nav-scroll {
  border-bottom: 1px solid rgba(202, 107, 24, 0.1);
  border-radius: 0;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  left: 0;
  margin: 0;
  position: fixed;
  top: -100px;
  transform: translateY(100px);
  transition: transform 0.5s;
  width: 100%;
}

  .navbar .navbar-nav {
    pointer-events: none;
    visibility: hidden;
  }
  .navbar.nav-scroll .navbar-nav {
    pointer-events: auto;
    visibility: visible;
  }

.navbar {
  position: absolute;
  z-index: 99;
  padding: 20px 0;
  width: 100%;
  border-radius: 30px;
  top: 0;
  left: 0;
}
  .navbar.nav-scroll {
    background: rgba(202, 107, 24, 0.2);
    backdrop-filter: blur(10px);
  }

.navbar-toggler {
  background-color: var(--color-secondary);
}
  .navbar-toggler:focus {
    box-shadow: 0 0 0 .25rem var(--color-secondary);
    background-color: var(--color-secondaryHover);
  }

.navbar .container-fluid {
  padding: 0 3vw;
}
.navbar .nav-item .nav-link {
  color: #000;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
  .navbar .nav-item .nav-link.active {
    font-weight: bold;
  }

.navbar .nav-item .nav-link:hover,
.navbar .nav-item .nav-link.active {
  color: var(--color-primary);
}

/* ===============================================================
   [ * hero Section ]
================================================================*/

.hero .content {
  background-image: linear-gradient(to left top, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  height: calc(100vh - 1rem);
}
.hero .info h6 {
  letter-spacing: 5px;
}
.hero .info h1 {
  font-size: 5.25rem;
  line-height: 1.1;
}

/* Illustrations */

.hero .illustration-tray {
  left: 0;
  object-fit: contain;
  object-position: left;
  position: absolute;
  top: 20%;
  width: 20%;
  z-index: 10;
}
.hero .illustration-plate {
  bottom: 5%;
  position: absolute;
  right: -30px;
  width: 30%;
  z-index: 10;
}

@media screen and (min-width: 576px) {
  .hero .content {
    border-radius: 1.5rem;
    height: calc(100vh - 3rem);
  }
}

/* ===============================================================
   [ * the-solution Section ]
================================================================*/

.solution-avatar {
  background-image: linear-gradient(to left top, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  transition: box-shadow 0.3s ease;
}
  .solution-avatar:hover {
    box-shadow: 0px 46px 77px 0px rgba(10, 43, 83, 0.1490196078);
  }

.solution-avatar img {
  height: 240px;
  margin-bottom: -20px;
  margin-top: -20px;
  width: 240px;
}

.solution-avatar--overflow-bottom img {
  margin-bottom: -30px;
  margin-top: 0;
}

.solution-avatar--overflow-extra img {
  height: 260px;
  margin-top: -40px;
  width: 260px;
}

/* ===============================================================
   [ * pricing Section ]
================================================================*/

#pricing[data-js-period="monthly"] .price-monthly { display: inline-block; }
#pricing[data-js-period="monthly"] .price-yearly { display: none; }
#pricing[data-js-period="monthly"] .price-period-switch--monthly { pointer-events: none; }
#pricing[data-js-period="yearly"] .price-monthly { display: none; }
#pricing[data-js-period="yearly"] .price-yearly { display: inline-block; }
#pricing[data-js-period="yearly"] .price-period-switch--yearly { pointer-events: none; }

.price-period-switch .form-switch {
  gap: 10px;
}
  .price-period-switch .form-check-input {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    cursor: pointer;
    float: none;
    margin: 0;
    padding: 10px 20px;
  }
    .price-period-switch .form-check-input:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 142, 74, 0.4);
    }
      .price-period-switch .form-switch .form-check-input {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      }
  .price-period-switch .form-check-label {
    cursor: pointer;
  }

  .price-period-switch-discount {
    background: transparent;
    border: 1px solid rgba(100, 100, 100, 1);
    color: rgba(100, 100, 100, 1);
  }
    #pricing[data-js-period="yearly"] .price-period-switch-discount {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      color: white;
    }

.price-card {
  border-radius: 1.25rem;
  background-color: var(--color-neutral);
}
  .price-card--highlighted {
    background-image: linear-gradient(to left top, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  }

#demo {
  padding-bottom: 200px;
}

.demo-qr-code img {
  border-radius: 0.5rem;
  border: 5px solid var(--color-neutral);
  max-width: 200px;
  padding: 10px;
  width: 40%;
}

@media screen and (min-width: 768px) {
  .demo-qr-code figcaption {
    font-size: 0.9rem !important;
  }
}

@media screen and (min-width: 992px) {
  .demo-qr-code figcaption {
    font-size: initial !important;
  }
}

/* .price--line-through { */
/*   background-color: transparent; */
/*   background-image: gradient(linear, 19.1% -7.9%, 81% 107.9%, color-stop(0, transparent), color-stop(.48, transparent), color-stop(.5, #000), color-stop(.52, transparent), color-stop(1, transparent)); */
/*   background-image: repeating-linear-gradient(163deg, transparent 0%, transparent 48%, #000 50%, transparent 52%, transparent 100%); */
/*   position: relative; */
/* } */
  /* .price--line-through::after { */
  /*   background: linear-gradient(169deg, transparent 47%, #000000 47%, #000000 53%, transparent 53%); */
  /*   content: ''; */
  /*   height: 110%; */
  /*   left: -5%; */
  /*   position: absolute; */
  /*   top: -5%; */
  /*   width: 110%; */
  /* } */


/* ===============================================================
   [ * imprint Section ]
================================================================*/

.imprint .navbar .navbar-nav {
  pointer-events: auto;
  visibility: visible;
}

.imprint .content {
  background-image: linear-gradient(to left top, #fcf9f2, #faf0dd, #fae6c9, #fadcb6, #fcd1a4, #fcd1a4, #fcd1a4, #fcd1a4, #fadcb6, #fae6c9, #faf0dd, #fcf9f2);
  border-radius: 1rem;
  min-height: calc(100vh - 1rem);
  padding-top: 80px;
}

@media screen and (min-width: 576px) {
  .imprint .content {
    border-radius: 1.5rem;
    min-height: calc(100vh - 3rem);
    padding-top: 100px;
  }
}

@media screen and (min-width: 768px) {
  .imprint .content {
    padding-top: 140px;
  }
}

/* ===============================================================
   [ * responsive Section ]
================================================================*/

@media screen and (max-width: 991px) {
  .section {
    padding: 70px 0;
  }

  .section-head h2,
  .section-head h2 span small {
    font-size: 30px;
  }

  /* Navbar */

  .navbar:has(.navbar-collapse.show),
  .navbar:has(.navbar-collapse.collapsing) {
    background: rgba(202, 107, 24, 0.2) !important;
    backdrop-filter: blur(10px);
  }

  .navbar .navbar-nav {
    pointer-events: auto;
    visibility: visible;
  }

  .navbar .container-fluid {
    padding: 0 20px;
  }

  .navbar .container-fluid .navbar-collapse {
    margin-top: 20px;
  }

  /* Layout */

  .hero .illustration-tray {
    display: none;
  }

  .hero .illustration-plate {
    width: 45%;
    bottom: 0;
    z-index: 1;
  }
}

@media screen and (max-width: 575px) {
  /* Layout */

  .hero .info h6 {
    font-size: 1rem !important;
  }

  .hero .info h1 {
    font-size: 3rem;
  }
}
